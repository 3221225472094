import { Space, Select, Button, Form, Divider, SelectProps } from "antd";
import React, { useEffect, useRef } from "react";
import { brandsData } from "../../data/brandsMinified";
import NextButtonFooter from "./NextButtonFooter";
import _ from "lodash";
import StepView from "./StepView";
import { styled } from "styled-components";
import { formatAmount } from "../../utils/monetary";
import { brandsLogos } from "../../data/brandsLogos";

const FancyH1 = styled.h1`
  font-size: 29px;
  line-height: 1em;
  background: -webkit-linear-gradient(45deg, #00B8FB, #03E864 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  strong {
    font-weight: 700;
  }
`;

const FormBox = styled.div`
  border: 2px solid #293276;
  border-radius: 25px;
  padding: 20px;
  color: #293276;
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  border: 2px solid #293276;
  border-radius: 25px;
  padding: 5px;
  font-size: 18px;
  color: #293276;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-arrow svg path {
    fill: #293276 !important;
  }
  .ant-select-selection-item {
    color: #293276 !important;
    font-size: 16px;
  }
`;

const CarEstimationItem = ({ image, text }: { image: string, text: React.ReactNode }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px', textAlign: 'center' }}>
      <img src={image} alt="icone" style={{ height: '40px' }} />
      <div style={{ fontSize: '18px', fontWeight: 500, lineHeight: '1.4em' }}>{text}</div>
    </div>
  );
}


const VehiculeStepView = ({ brand, brandModel, onValuesChange, handleNext }: {
  brand: string | null,
  brandModel: string | null,
  onValuesChange: (values: any) => void,
  handleNext: () => void,
}) => {
  const [form] = Form.useForm();

  // @ts-ignore
  const brandData = brand ? brandsData[brand] : undefined;
  // @ts-ignore
  const brandModelData = brandModel ? brandData?.[brandModel] : undefined;

  const brandsOptions = _.sortBy(
    Object.keys(brandsData).map((value) => ({ label: value, value })),
    option => ({ 'Tesla': -3, 'Cupra': -2, 'Peugeot': -1 }[option.value] ?? 0)
  );
  const brandModelsOptions = Object.keys(brandData ?? {})?.map((value) => ({ label: value, value }))

  useEffect(() => {
    if(!brandModelsOptions.some(x => x.value === form.getFieldValue('brandModel'))) {
      form.setFieldValue('brandModel', null);
    }
  });

  useEffect(() => {
    if(brand && brandModel) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
    }
  }, [brand, brandModel]);
  
  const batteryCapacityInKwh = brandModelData ?? 0;
  const economyPerYear = batteryCapacityInKwh * (60 - 18) * 52.2;
  const amortizationTime = Math.max(0, Math.ceil(129900 / (economyPerYear / 12)));
  const costPerCharge = batteryCapacityInKwh * 18;

  return (
    <StepView>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        autoComplete="off"
        layout="vertical"
        onFinish={handleNext}
        style={{ maxWidth: "calc(100vw - 20px)", boxSizing: "border-box" }}
      >
        <FancyH1>Recevez <strong>la meilleure offre</strong> d’installation en <strong>1&nbsp;minute</strong></FancyH1>
        <FormBox style={{ gap: '10px' }}>
          <div style={{ fontSize: '18px', fontWeight: 600, lineHeight: '1em' }}>Sélectionnez votre véhicule</div>
          <Form.Item
            name="brand"
            rules={[{ required: true, message: 'Veuillez sélectionner une marque !' }]}
            style={{ flex: 1, marginBottom: 0 }}
          >
            <StyledSelect
              placeholder="Marque"
              options={brandsOptions}
            />
          </Form.Item>
          <Form.Item
            name="brandModel"
            rules={[{ required: true, message: 'Veuillez sélectionner un modèle !' }]}
            style={{ flex: 1, marginBottom: 0 }}
          >
            <StyledSelect
              placeholder="Modèle"
              options={brandModelsOptions}
            />
          </Form.Item>
          {
            brand && brandsLogos[brand] && (<>
              <img src={brandsLogos[brand]} alt={brand} style={{ height: '74px', alignSelf: 'center' }} />
            </>)
          }
        </FormBox>
        {
          brand && brandModel && brandModelData && (<>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', marginTop: '20px', color: '#293276' }}>
              <CarEstimationItem image="/tirelire.svg" text={<>Economisez par an <strong>{formatAmount(economyPerYear)}</strong></>} />
              <CarEstimationItem image="/economie.svg" text={<>Votre achat amorti en <strong>{amortizationTime} mois</strong></>} />
              <CarEstimationItem image="/battery.svg" text={<>Rechargez en moins de <strong>4 heures</strong></>} />
              <CarEstimationItem image="/range.svg" text={<>Coût par recharge <strong>{formatAmount(costPerCharge)}</strong></>} />
            </div>
          </>)
        }
        <NextButtonFooter hidden={!brand || !brandModel} />
      </Form>
    </StepView>
  );

};

export default VehiculeStepView;