import React, { useEffect, useState } from 'react';

function CoproprieteEntrepriseForm() {
  const [once, setOnce] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';

    // @ts-ignore
    if(document.getElementById('hubspotForm').innerHTML !== '') {
      return;
    }
    // @ts-ignore
    document.getElementById('hubspotForm').appendChild(script);

    const createHubSpotForm = () => {
      if(once) return;
      setOnce(true);
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '25644138',
          formId: '42720475-be2c-4202-ba84-d9392a017b6e',
        });
      }
    };

    script.onload = createHubSpotForm;
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div id='hubspotForm' style={{ padding: '5px', maxWidth: "30em" }}>
      </div>
    </div>
  );
}

export default CoproprieteEntrepriseForm;