import styled from "styled-components";


export default styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2em;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #293276;
`;