import H2 from "../components/text/H2";
import { AutoComplete, Button, Form, Input } from "antd";
import H1 from "../components/text/H1";
import React, { useEffect, useState } from "react";
import NextButtonFooter from "./NextButtonFooter";
import { styled } from "styled-components";
import PlacesAutocomplete, { Suggestion, geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-places-autocomplete";
import StyledInput from "../components/StyledInput";

const StyledAutoComplete = styled(AutoComplete)`
  .ant-select-selector {
    border: 2px solid #BEC0CB !important;
    border-radius: 25px !important;
    height: 45px !important;
    &:hover {
      border: 2px solid #293276 !important;
    }
    input {
      height: 45px !important;
      line-height: 45px !important;
      font-size: 18px !important;
      font-weight: 500 !important;
      color: #293276 !important;
      font-family: unset;
    }
    .ant-select-selection-placeholder {
      line-height: 45px !important;
      font-size: 18px !important;
      font-weight: 500 !important;
    }
  }
`;

function stringifyAddress(address?: { addressLine1?: string, addressPostalCode?: string, addressCity?: string }) {
  return address?.addressLine1 && address?.addressPostalCode && address?.addressCity ? `${address.addressLine1}, ${address.addressPostalCode} ${address.addressCity}` : null;
}

const PersonalDetailsStepView = ({ formValues, onValuesChange, handleNext }: {
  formValues: Record<string, any>,
  onValuesChange: (values: any) => void,
  handleNext: () => void,
}) => {

  const [formRef] = Form.useForm();

  const [addressInput, setAddressInput] = useState(
    stringifyAddress(formValues) ?? ""
  );

  const handleSelectAddress = async (address: any, placeId: any) => {
    const results = await geocodeByAddress(address);
    const [place] = await geocodeByPlaceId(placeId);

    const line1 = results[0].address_components.filter((x: any) => !x.types.includes("postal_code") && !x.types.includes("country") && !x.types.includes("political")).map((x: any) => x.long_name).join(" ");
    const postalCode = place.address_components.find(c => c.types.includes('postal_code'))?.long_name;
    const city = place.address_components.find(c => c.types.includes('locality'))?.long_name;

    setAddressInput(`${line1}, ${postalCode} ${city}`);
    formRef.setFieldValue("addressInput", `${line1}, ${postalCode} ${city}`);
    onValuesChange({ addressLine1: line1, addressPostalCode: postalCode, addressCity: city });
  };

  return (
    <div>
      <div style={{ margin: "auto" }}>
        <H1 style={{ marginBottom: "20px", marginLeft: 0, marginRight: 0 }}>Vos coordonnées</H1>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <img src="/coordonnees.svg" width={32} height={32} />
          <H2 style={{ marginBottom: "10px" }}>Afin de vous attribuer un technicien IRVE de&nbsp;votre&nbsp;secteur</H2>
        </div>
        <Form
          form={formRef}
          style={{
            maxWidth: 600,
            width: "100%",
          }}
          initialValues={formValues}
          onValuesChange={onValuesChange}
          autoComplete="off"
          layout="vertical"
          onFinish={handleNext}
          validateTrigger="onBlur"
        >
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Veuillez entrer votre prénom !' }]}
          >
            <StyledInput placeholder="* Prénom" />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Veuillez entrer votre nom de famille !' }]}
          >
            <StyledInput placeholder="* Nom de famille" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Veuillez entrer votre email !' }, { type: 'email', message: 'Veuillez entrer un email valide !' }]}
          >
            <StyledInput type="email" placeholder="* Adresse e-mail" />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: 'Veuillez entrer votre numéro de téléphone !' }, { pattern: /^0[1-9]\d{8}$/, message: 'Veuillez entrer un numéro de téléphone valide !' }]}
          >
            <StyledInput type="tel" placeholder="* Numéro de téléphone" />
          </Form.Item>
          
          <Form.Item
            name="addressInput"
            rules={[ form => ({
              validator(_, value) {
                if(!formValues.addressLine1 || !formValues.addressPostalCode || !formValues.addressCity) {
                  return Promise.reject(new Error('Veuillez sélectionner une adresse dans la liste !'));
                }
                return Promise.resolve();
              },
            })]}
          >
            <PlacesAutocomplete
              onChange={setAddressInput}
              onSelect={handleSelectAddress}
              value={addressInput}
              searchOptions={{
                types: ['address'],
                componentRestrictions: { country: 'fr' },
              }}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => {return (
                <div>
                  <StyledAutoComplete
                    value={addressInput}
                    onBlur={getInputProps().onBlur}
                    onChange={(x: any) => getInputProps().onChange({ target: { value: x } })}
                    onSearch={getInputProps().onSearch}
                    onSelect={(x: any) => getSuggestionItemProps(suggestions?.find((y: any) => y.description === x)).onClick()}
                    options={suggestions?.map((x: any) => ({ value: x.description })) ?? []}
                    placeholder="* Adresse d'installation"
                  />
                </div>
              )}}
            </PlacesAutocomplete>
          </Form.Item>

          <NextButtonFooter />
        </Form>
      </div>
    </div>
  );

};

export default PersonalDetailsStepView;