import StyledCard from "../purchaseForm/view/steps/cards/StyledCard";
import CardButton from "../purchaseForm/view/steps/cards/CardButton";


interface IHabitationCardProps {
  image: string;
  habitationType: string;
  description: string;
  price?: string;
  selected: boolean;
  onClick: () => void;
}

const HabitationCard = ({ image, habitationType, description, price, selected, onClick }: IHabitationCardProps) => {
  return (
    <StyledCard
      selected={selected}
      onClick={onClick}
      style={{ border: "none" }}
    >
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <img src={image} alt={habitationType} style={{ width: "40px", height: "40px", margin: "10px" }}/>
        <div style={{ fontSize: "18px", fontWeight: 500, color: "#293276" }}>{habitationType}</div>
      </div>
      <div>
        {description}
      </div>
    </StyledCard>
  );
}

export default HabitationCard;
