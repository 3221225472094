import { styled } from "styled-components"

export const StyledCardsRow = styled.div`
  display: flex;
  align-items: stretch;
  gap: 1.5em;
  align-self: center;
  @media (max-width: 768px) {
    max-width: 25em;
    flex-direction: column;
  }
`;
