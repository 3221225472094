import { PhoneFilled } from "@ant-design/icons";

const Footer = () => {
  return (
    <div style={{ display: "flex", color: "#293276", fontSize: "0.8em", alignItems: "center", gap: "15px", marginTop: "5em", maxWidth: "30em", marginBottom: "86px" }}>
      <div style={{ backgroundColor: "#293276", borderRadius: "25px", color: "white", fontSize: "16px", fontWeight: "600", whiteSpace: "nowrap", padding: "5px 20px" /* shadow : linear-gradient(90deg, #00B9FD 0%, #00FE6D 100%) */, boxShadow: "rgb(41 194 179) 0px 0px 14px" }}>
        01 76 41 12 93
      </div>
      <div>
        <div style={{ fontSize: "16px" }} >
          24h/24 7j/7
        </div>
        <div>
          service gratuit<br/>+ prix d'un appel local
        </div>
      </div>
    </div>
  );
};

export default Footer;