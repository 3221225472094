import React from 'react';
import styled from 'styled-components';

interface Props {
  percentage: number;
}

const ProgressBar: React.FC<Props> = ({ percentage }) => {
  return (
    <Container>
      <Progress style={{ width: `${percentage}%` }} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #EBEBEB;
  height: 2px;
`;

const Progress = styled.div`
  height: 100%;
  background: linear-gradient(90deg,#00bafd 0%,#00fe6e 100%);
  transition: width 0.3s ease-in-out;
`;

export default ProgressBar;