import CounterCard from "./cards/CounterCard";
import H1 from "../components/text/H1";
import { StyledCardsRow } from "./cards/StyledCardsRow";
import NextButtonFooter from "./NextButtonFooter";

const CurrentStepView = ({
  currentsData,
  currentKey,
  handleCurrentKeyChange,
  handleNext,
}: {
  currentsData: Record<'monophase' | 'triphase', { name: string, description: string, ribbon?: string }>,
  currentKey: string,
  handleCurrentKeyChange: (value: string) => void,
  handleNext: () => void,
}) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <H1>Sélectionnez la puissance de votre compteur</H1>
      <StyledCardsRow>
        {Object.entries(currentsData).map(([key, currentData]) => (
          <CounterCard
            habitationType={currentData.name}
            description={currentData.description}
            ribbon={currentData.ribbon}
            selected={currentKey === key}
            onClick={() => {
              handleCurrentKeyChange(key);
              handleNext();
            }}/>
        ))}
      </StyledCardsRow>

      {/*<NextButtonFooter onClick={handleNext} />*/}
    </div>
  );
};

export default CurrentStepView;
