import _ from 'lodash';
import PurchaseFormContainer from './purchaseForm/PurchaseFormContainer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './home/Home';
import CoproprieteEntrepriseForm from './CoproprieteEntrepriseForm';
import { Result } from 'antd';
import { GlobalLayout } from './purchaseForm/view/PurchaseFormView';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <Home />
        } />
        <Route path="/maison" element={
          <PurchaseFormContainer />
        } />
        <Route path="/coproprieteEntreprise" element={
          <CoproprieteEntrepriseForm />
        } />
        <Route path="/success" element={
          <GlobalLayout currentStep={9}>
            <Result
              status="success"
              title="Votre estimation a été envoyée sur votre boîte mail et par SMS, nous venons de vous attribuer un expert, il est en charge de vous accompagner dans votre projet."
            />
          </GlobalLayout>
        } />
        <Route path="/paymentSuccess" element={
          <GlobalLayout currentStep={9}>
            <Result
              status="success"
              title="Vous recevrez dans quelques instants un mail de confirmation, un conseiller vous appellera afin de planifier avec vous le rendez-vous d’installation."
            />
          </GlobalLayout>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;