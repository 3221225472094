import NewSyntheseStepView from "./view/steps/SyntheseStepView";
import { PurchaseFormState } from "./PurchaseFormState";
import { formatAmount } from "./utils/monetary";
import _ from "lodash";
import React from "react";
import { DeepPartial } from "./utils/primitives";
import axios from "axios";
import { brandsData } from "./data/brandsMinified";
import productsData from "./data/products";
import { apiUrl } from "../config";

export type SyntheseProps = Parameters<typeof NewSyntheseStepView>[0];

export const mapStateToSyntheseProps = (
  state: PurchaseFormState,
  mergeState: React.Dispatch<React.SetStateAction<DeepPartial<PurchaseFormState>>>,
  handleNext: any,
): SyntheseProps => {
  const { borne, current, distanceInMeters, options } = state.formValues;

  const selectedBorneData = productsData.bornesData.find(borneData => borneData.key === borne && borneData.availableForCurrents.includes(current));
  const selectedCurrentData = productsData.currentsData[current as keyof typeof productsData.currentsData];
  const selectedOptionsData = productsData.optionsData.filter(optionData => options[optionData.key]);

  const onShow = async () => {
    mergeState({ totalAmount: null, depositPercentage: null, depositAmount: null, vatRatePercentage: null });
    const { totalAmount, depositPercentage, depositAmount, vatRatePercentage } = (await axios.post(apiUrl+'/purchaseForm/computeTotalAmount', state.formValues)).data;
    mergeState({ totalAmount, depositPercentage, depositAmount, vatRatePercentage });
  };

  const baseProps = {
    borneType: selectedBorneData?.borneType ?? '',
    borneTitle: selectedBorneData?.name ?? '',
    borneDescription: `${selectedBorneData?.description}\n${selectedCurrentData?.name}\n${selectedOptionsData.map(option => option.name).join('\n')}`,
    borneProperties: selectedBorneData?.properties ?? [],
    borneImage: selectedBorneData?.image ?? '',
    onShow,
  };

  if(state.totalAmount == null) {
    return {
      ...baseProps,
      arePricesLoading: true,
    };
  }

  const totalAmount = state.totalAmount;
  const depositPercentage = state.depositPercentage;
  const depositAmount = state.depositAmount;
  const vatRatePercentage = state.vatRatePercentage;

  // @ts-ignore
  const batteryCapacityInKwh = brandsData[state.formValues.brand]?.[state.formValues.brandModel] ?? 0;
  const economyPerYear = batteryCapacityInKwh * (60 - 18) * 52.2;

  const savingsAfter5Years = Math.max(0, economyPerYear * 5 - totalAmount);

  const onClickOnPayButton = async () => {
    // @ts-ignore
    window.dataLayer = [...(window.dataLayer || []),
      { event: 'purchase' },
    ];
    handleNext();
  };

  const onClickOnQuoteButton = async () => {
    mergeState({ quoteButtonState: 'loading' });
    // @ts-ignore
    window.dataLayer = [...(window.dataLayer || []),
      { event: 'purchase' },
    ];
    const result = await axios.post(apiUrl+'/purchaseForm/submit', state.formValues);
    window.location.href = "/success";
  };

  return {
    ...baseProps,
    arePricesLoading: false,
    totalAfterTaxDeduction: formatAmount(Math.max(0, totalAmount-50000)),
    savingsAfter5Years: savingsAfter5Years > 0 ? formatAmount(savingsAfter5Years) : null,
    depositPercentage: `${depositPercentage}%`,
    depositAmount: formatAmount(depositAmount),
    vatRate: vatRatePercentage != null ? `${Intl.NumberFormat('fr-FR').format(vatRatePercentage)}%` : '',
    isPayButtonLoading: state.payButtonState === 'loading',
    isQuoteButtonLoading: state.quoteButtonState === 'loading',
    onClickOnPayButton,
    onClickOnQuoteButton,
  };
}

