import axios from "axios";
import { useEffect, useState } from "react";
import { PurchaseFormState } from "../../PurchaseFormState";
import { loadStripe } from "@stripe/stripe-js";
import CommandeStepView from "./CommandeStepView";
import { Elements } from "@stripe/react-stripe-js";
import { Spin } from "antd";
import productsData from "../../data/products";
import { brandsData } from "../../data/brandsMinified";
import { apiUrl, stripePk } from "../../../config";

const CommandeStepContainer = (props: {
  state: PurchaseFormState,
  onValuesChange: (values: any) => void,
}) => {

  const initialValues = props.state.formValues;

  const [responseData, setResponseData] = useState<any>(null);

  useEffect(() => {
    setResponseData(null);
    axios.post(apiUrl+'/purchaseForm/getCommandeStep', props.state.formValues).then((response) => {
      setResponseData(response.data);
    });
  }, []);

  const updateFormData = async (values: any) => {
    props.onValuesChange(values);
    const response = await axios.post(apiUrl+'/purchaseForm/updateFormData?secretKey='+encodeURIComponent(responseData.secretKey), {
      ...props.state.formValues,
      ...values,
    });
  }

  const submitKlarnaPayment = async (values: any) => {
    const response = await axios.post(apiUrl+'/purchaseForm/submitAndPay', {
      ...props.state.formValues,
      ...values,
      paymentMethod: 'klarna',
    });
    window.location.href = response.data.redirectUrl;
  }

  const stripePromise = loadStripe(stripePk);

  if(!responseData || !stripePromise) {
    return <div style={{ padding: '20px' }}><Spin /></div>;
  }
  // @ts-ignore
  const batteryCapacityInKwh = brandsData[props.state.formValues.brand]?.[props.state.formValues.brandModel] ?? 0;
  const economyPerYear = batteryCapacityInKwh * (60 - 18) * 52.2;

  const savingsAfter5Years = Math.max(0, economyPerYear * 5 - responseData.totalAmount) || null;
  
  const selectedBorneData = productsData.bornesData.find(borneData => borneData.key === props.state.formValues.borne && borneData.availableForCurrents.includes(props.state.formValues.current));

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: responseData.stripeClientSecret }}>
      <CommandeStepView
        items={responseData.items}
        preTaxAmount={responseData.preTaxAmount}
        vatAmount={responseData.totalAmount-responseData.preTaxAmount}
        vatRatePercentage={responseData.vatRatePercentage}
        totalIncludingTax={responseData.totalAmount}
        depositPercentage={responseData.depositPercentage}
        depositAmount={responseData.depositAmount}
        savingsAfter5Years={savingsAfter5Years}
        properties={[
          ...props.state.formValues.options['moins72h'] ? [{ key: 'moins72h', label: 'Installation en moins de 72h', }] : [],
          ...selectedBorneData?.properties.find(x => x.key == 'garantie') ? [{ key: 'garantie', label: 'Garantie constructeur' }] : [],
          { key: 'paiementPlusieursFois', label: 'Paiement en plusieurs fois', },
        ]}
        initialValues={initialValues}
        onValuesChange={props.onValuesChange}
        updateFormData={updateFormData}
        submitKlarnaPayment={submitKlarnaPayment}
      />
    </Elements>
  );


}

export default CommandeStepContainer;