const ListWithIcons = ({ rows }: { rows: { imagePath: string, label: string, description?: string, value?: string }[] }) => {
  return (
    <table style={{ verticalAlign: "center" }}>
      {rows.map(x => <tr style={{ paddingBottom: "15px" }}>
        <td style={{ verticalAlign: "middle", width: "24px", paddingRight: "10px" }}><img src={x.imagePath} alt="" width={24} style={{ verticalAlign: "middle" }} /></td>
        <td style={{ display: "table-cell", verticalAlign: "middle" }} colSpan={x.value ? 1 : 2}>
          <div style={{ fontWeight: 600 }}>{x.label}</div>
          <div style={{ fontSize: "0.7em" }}>{x.description}</div>
        </td>
        {x.value && <td style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "15px" }}>{x.value}</td>}
      </tr>)}
    </table>
  );
}

export default ListWithIcons;