import ProgressBar from "./ProgressBar";
import styled from "styled-components";
import React from "react";
import { HomeFilled } from "@ant-design/icons";

const StyledHeader = styled.header`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  flex: 2.5;
`;

interface HeaderProps {
  numberOfSteps: number;
  currentStep: number;
}

const Header = ({ numberOfSteps, currentStep }: HeaderProps) => {
  const percentage = 100*currentStep/(numberOfSteps - 1);

  return (
    <div>
      <StyledHeader>
        <img src={"/borne4you.svg"} style={{ height: "25px", display: 'inline' }}/>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div>
            <div style={{ fontSize: '0.9rem', color: '#293276', fontWeight: 600 }}>
              Étape {currentStep+1}/{numberOfSteps}
            </div>
          </div>
        </div>
      </StyledHeader>
      <ProgressBarContainer>
        <ProgressBar percentage={percentage}/>
      </ProgressBarContainer>
    </div>
  );
};

export default Header;