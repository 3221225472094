import { Button } from "antd";

const FancyButton = (props: {
  onClick?: () => void,
  children: React.ReactNode,
  style?: React.CSSProperties,
  loading?: boolean,
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px" }}
    >
      <Button
        style={{
          width: "340px",
          maxWidth: "80vw",
          backgroundColor: "#293276",
          color: "white",
          padding: "6px",
          height: "unset",
          fontSize: "22px",
          fontWeight: "600",
          boxShadow: "rgb(41 194 179) 0px 0px 14px",
          background: "linear-gradient(90deg, #00B9FD 0%, #00FE6D 100%)",
          ...props.style,
        }}
        shape="round"
        loading={props.loading}
        htmlType={props.onClick ? undefined : "submit"}
        onClick={props.onClick || undefined}
      >{props.children}</Button>
    </div>
    );
};

export default FancyButton;