import StyledCard from "./StyledCard";
import CardButton from "./CardButton";


interface ICompteurCardProps {
  habitationType: string;
  description: string;
  price?: string | React.ReactNode;
  selected: boolean;
  ribbon?: string;
  onClick: () => void;
}

const CompteurCard = ({ habitationType, description, price, selected, ribbon, onClick }: ICompteurCardProps) => {
  return (
    <StyledCard
      selected={selected}
      ribbon={ribbon}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ fontSize: "18px", fontWeight: "700" }}>{habitationType}</div>
          { price && <div style={{ fontSize: "20px", fontWeight: "700" }}>{price}</div> }
        </div>
        <div style={{ paddingBottom: "18px", fontSize: "18px", fontWeight: "500" }}>
          {description}
        </div>
      </div>
      <CardButton selected={selected} onClick={onClick}>Choisir</CardButton>
    </StyledCard>
  );
}

export default CompteurCard;
