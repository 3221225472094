export const brandsLogos : Record<string, string> = {
  'BMW': '/brands/bmw.svg',
  'Cupra': '/brands/cupra.svg',
  'Dacia': '/brands/dacia.svg',
  'Hyundai': '/brands/hyundai.svg',
  'Kia': '/brands/kia.svg',
  'Mercedes': '/brands/mercedes.svg',
  'MG': '/brands/mg.svg',
  'Nissan': '/brands/nissan.svg',
  'Peugeot': '/brands/peugeot.svg',
  'Renault': '/brands/renault.svg',
  'Tesla': '/brands/tesla.svg',
  'Toyota': '/brands/toyota.svg',
  'Volkswagen': '/brands/volkswagen.svg',
};