import { formatAmount } from "../../utils/monetary";
import CounterCard from "./cards/CounterCard";
import { StyledCardsRow } from "./cards/StyledCardsRow";
import H1 from "../components/text/H1";
import NextButtonFooter from "./NextButtonFooter";

const OptionsStepView = ({
  availableOptionsData,
  selectedOptions,
  handleOptionToggle,
  handleNext,
}: {
  availableOptionsData: { key: string, name: string, description: string, price: number, originalPrice?: number }[],
  selectedOptions: Record<string, boolean>,
  handleOptionToggle: (key: string) => void,
  handleNext: () => void,
}) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <H1>Mes options</H1>
      <StyledCardsRow>
        {availableOptionsData.map((optionData) => (
          <CounterCard
            habitationType={optionData.name}
            description={optionData.description}
            ribbon={optionData.price === 0 && optionData.originalPrice != undefined ? 'Offert' : undefined}
            price={
              optionData.originalPrice != undefined
              ? <>
                <span style={{ textDecoration: 'line-through', color: '#999999', marginRight: '5px' }}>{formatAmount(optionData.originalPrice)}</span>
                <span style={{ color: '#000000' }}>{
                  optionData.price !== 0 && '+'+formatAmount(optionData.price)
                }</span>
              </>
              : '+'+formatAmount(optionData.price)
            }
            selected={selectedOptions[optionData.key]}
            onClick={() => handleOptionToggle(optionData.key)}/>
        ))}
      </StyledCardsRow>

      <NextButtonFooter onClick={handleNext} />
    </div>
  );
};

export default OptionsStepView;
