import React, { useState } from "react";
import { formatAmount } from "../../utils/monetary";
import H2 from "../components/text/H2";
import BornePropertiesList from "./cards/BornePropertiesList";
import { Form, Input, Radio } from "antd";
import StyledInput from "../components/StyledInput";
import { PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import FancyButton from "../components/FancyButton";
import StyledRadio from "../components/StyledRadio";
import axios from "axios";
import { apiUrl } from "../../../config";

const CommandeRow = (props: {
  title: React.ReactNode,
  value: React.ReactNode,
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "2px" }}>
      <div>{props.title}</div>
      <div>{props.value}</div>
    </div>
  );
}

const CommandRowSeparator = () => {
  return (
    <div style={{ height: "2px", borderBottom: "1px solid #ccc", margin: "5px 0" }} />
  );
}

const CommandeStepView = (props: {
  items: {
    title: string,
    unitPreTaxAmount: number,
    quantity: number,
  }[],
  preTaxAmount: number,
  vatAmount: number,
  vatRatePercentage: number,
  totalIncludingTax: number,
  depositAmount: number,
  depositPercentage: number,
  savingsAfter5Years: number | null,
  properties: { key: string, label: string, description?: string, value?: string, }[],
  initialValues: Record<string, any>,
  onValuesChange: (values: any) => void,
  updateFormData: (values: any) => Promise<void>,
  submitKlarnaPayment: (values: any) => Promise<void>,
}) => {
  const [formRef] = Form.useForm();

  const customerType = Form.useWatch('customerType', formRef) as 'individual' | 'company';

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'klarna'>('stripe');

  const submitStripePayment = async (values: any) => {
    if(!stripe || !elements) {
      return;
    }

    await props.updateFormData(values);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/paymentSuccess`,
      },
    });

    if(error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message ?? null);
    } else {
      setMessage("Une erreur inconnue est survenue.");
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      setMessage(null);
      if(paymentMethod === 'stripe') {
        await submitStripePayment(values);
      } else if(paymentMethod === 'klarna') {
        await props.submitKlarnaPayment(values);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", color: "#293276", gap: "15px", paddingTop: "10px", maxWidth: "500px", }}>
      <div style={{ display: "flex", flexDirection: "column", background: "#293276", color: "#fff", padding: "2px", borderRadius: "25px" }}>
        <div style={{ display: "flex", flexDirection: "column", background: "#fff", color: "#293276", borderRadius: "25px", padding: "15px" }}>
          <H2>Votre commande</H2>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {
              props.items.map(item => (<>
                <CommandeRow title={item.title} value={<>{item.quantity}&nbsp;×&nbsp;<strong>{formatAmount(item.unitPreTaxAmount)}</strong></>} />
                <CommandRowSeparator />
              </>))
            }
            <CommandeRow
              title="Sous-total"
              value={<strong>{formatAmount(props.preTaxAmount)}</strong>}
            />
            <CommandRowSeparator />
            <CommandeRow
              title={`TVA (${props.vatRatePercentage}%)`}
              value={<strong>{formatAmount(props.vatAmount)}</strong>}
            />
            <CommandRowSeparator />
            <CommandeRow
              title={<strong>Total TTC</strong>}
              value={<strong>{formatAmount(props.totalIncludingTax)}</strong>}
            />
            <CommandRowSeparator />
            <CommandeRow
              title={<><strong>Acompte de {props.depositPercentage}% à régler</strong> (TTC&nbsp;{props.vatRatePercentage}%)</>}
              value={<strong>{formatAmount(props.depositAmount)}</strong>}
            />
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "48px auto", gap: "10px", padding: "15px", alignItems: "center" }}>
          <img src="/creditImpot.svg" style={{ width: "100%", fill: "#fff" }} />
          <div>Installation éligible au crédit d'impôt jusqu'à <strong>500 €</strong></div>
          {
            props.savingsAfter5Years != null && (<>
              <div style={{ height: "1px", backgroundColor: "#fff", margin: "0 5px", gridColumn: "1 / span 2" }} />
              <img src="/economieCommande.svg" style={{ width: "100%", fill: "#fff" }} />
              <div>Votre borne vous fera économiser <strong>{formatAmount(props.savingsAfter5Years)}</strong> sur 5 ans</div>
            </>)
          }
        </div>
      </div>
      <div style={{ fontSize: "12px" }}>Le crédit d’impôt est calculé sur le prix d’achat TTC de l’équipe­ment qui figurera sur votre facture, pose comprise. <strong>Vous recevrez dans l’email de confirmation de votre commande les indications nécessaires à reporter sur votre déclaration de revenus pour en bénéficier.</strong>{/*TODO : plus d'informations>*/}</div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", border: "2px solid #D5D7E6", borderRadius: "25px", padding: "10px", gap: "10px" }}>
        <div style={{ fontSize: "14px", fontWeight: "600" }}>Paiement sécurisé</div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <img src="/visa.svg" height="14" />
          <img src="/mastercard.svg" height="21" />
          <img src="/amex.svg" height="14" />
        </div>
      </div>
      <BornePropertiesList properties={props.properties} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <H2>Détails de facturation</H2>
        <Form
          form={formRef}
          style={{
            maxWidth: 600,
            width: "100%",
          }}
          initialValues={props.initialValues}
          onValuesChange={props.onValuesChange}
          onFinish={handleSubmit}
          autoComplete="off"
          layout="vertical"
          validateTrigger="onBlur"
        >
          <Form.Item
            name="customerType"
            rules={[{ required: true, message: 'Veuillez entrer votre prénom !' }]}
          >
            <Radio.Group>
              <StyledRadio value="individual">Particulier</StyledRadio>
              <StyledRadio value="company">Entreprise</StyledRadio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Veuillez entrer votre prénom !' }]}
          >
            <StyledInput placeholder="* Prénom" />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Veuillez entrer votre nom de famille !' }]}
          >
            <StyledInput placeholder="* Nom de famille" />
          </Form.Item>

          {
            customerType === 'company' && (
              <Form.Item
                name="companyName"
                rules={[{ required: true, message: 'Veuillez entrer le nom de votre entreprise !' }]}
              >
                <StyledInput placeholder="* Nom de l'entreprise" />
              </Form.Item>
            )
          }

          <Form.Item
            name="addressLine1"
            rules={[{ required: true, message: 'Veuillez entrer votre adresse !' }]}
          >
            <StyledInput placeholder="* Numéro et nom de rue" />
          </Form.Item>

          <Form.Item
            name="addressPostalCode"
            rules={[{ required: true, message: 'Veuillez entrer votre code postal !' }]}
          >
            <StyledInput placeholder="* Code postal" />
          </Form.Item>

          <Form.Item
            name="addressCity"
            rules={[{ required: true, message: 'Veuillez entrer votre ville !' }]}
          >
            <StyledInput placeholder="* Ville" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Veuillez entrer votre email !' }, { type: 'email', message: 'Veuillez entrer un email valide !' }]}
          >
            <StyledInput type="email" placeholder="* Adresse e-mail" />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: 'Veuillez entrer votre numéro de téléphone !' }, { pattern: /^0[1-9]\d{8}$/, message: 'Veuillez entrer un numéro de téléphone valide !' }]}
          >
            <StyledInput type="tel" placeholder="* Numéro de téléphone" />
          </Form.Item>
          
          <div style={{ border: "2px solid #BEC0CB", borderRadius: "25px", padding: "10px" }}>
            {message && <div id="payment-message">{message}</div>}
            <Radio.Group value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div><StyledRadio value="stripe">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                  <div style={{ marginRight: "5px" }}>Carte bancaire</div>
                  <img src="/visa.svg" height="12" />
                  <img src="/mastercard.svg" height="18" />
                  <img src="/amex.svg" height="12" />
                </div>
              </StyledRadio></div>
              {
                stripe && elements && (
                  <div style={{ display: !isLoading && paymentMethod === 'stripe' ? "block" : "none" }}>
                    <PaymentElement id="payment-element" />
                  </div>
                )
              }
              <div><StyledRadio value="klarna">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                  <div style={{ marginRight: "5px" }}>3x sans frais avec Klarna</div>
                  <img src="/klarna.svg" height="18" />
                </div>
              </StyledRadio></div>
              <p style={{ fontSize: "12px", color: "#293276" }}>Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de votre visite du site web, et pour d’autres raisons décrites dans notre politique de confidentialité.</p>
              {
                (paymentMethod === 'klarna' || (stripe && elements && paymentMethod === 'stripe')) && (
                  <FancyButton
                    loading={isLoading}
                  >Payer</FancyButton>
                )
              }
            </Radio.Group>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CommandeStepView;