import { Modal } from "antd";
import PurchaseFormContainer from "../purchaseForm/PurchaseFormContainer";
import './Home.css';
import HabitationCard from "./HabitationCard";
import { StyledCardsRow } from "../purchaseForm/view/steps/cards/StyledCardsRow";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const FancyH1 = styled.h1`
  font-size: 29px;
  line-height: 1em;
  background: -webkit-linear-gradient(45deg, #00B8FB, #03E864 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  strong {
    font-weight: 700;
  }
`;

export const Home = () => {
  const navigate = useNavigate();

  return (<>
    <div style={{ filter: 'blur(7px)' }}><PurchaseFormContainer /></div>
    <Modal open={true} footer={null} closable={false} className="home-modal" maskStyle={{ backgroundColor: 'rgba(21,26,61, 0.7)' }} width={1000} style={{ top: "50px" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <FancyH1 style={{
          color: 'white',
          fontWeight: 700,
        }}>Sélectionnez votre situation</FancyH1>
        <StyledCardsRow>
          <HabitationCard
            image="/home.svg"
            habitationType="Maison"
            description="L’installation en maison individuelle ne nécessite pas une pré-visite de notre part et se fait en 72h ouvrée."
            selected={false}
            onClick={() => {
              navigate('/maison');
            }}
          />
          <HabitationCard
            image="/copropriete.svg"
            habitationType="Copropriété"
            description="Une installation en copropriété nécessite une pré-visite de notre part et peut prendre plusieurs semaines."
            selected={false}
            onClick={() => {
              navigate('/coproprieteEntreprise');
            }}
          />
          <HabitationCard
            image="/entreprise.svg"
            habitationType="Entreprise"
            description="Une installation en entreprise nécessite une pré-visite de notre part et peut prendre plusieurs semaines."
            selected={false}
            onClick={() => {
              navigate('/coproprieteEntreprise');
            }}
          />
        </StyledCardsRow>
      </div>
    </Modal>
  </>);
}