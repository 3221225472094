import axios from 'axios';
import _, { merge } from 'lodash';
import { useEffect, useState } from 'react';
import { PurchaseFormState, defaultPurchaseFormState } from './PurchaseFormState';
import { DeepPartial } from './utils/primitives';
import { PurchaseFormView } from './view/PurchaseFormView';
import productsData from "./data/products";
import { mapStateToSyntheseProps } from './mapStateToProps';

function PurchaseFormContainer() {
  const [state, setState] = useState(defaultPurchaseFormState);

  const mergeState = (newValuesOrFn: DeepPartial<PurchaseFormState> | ((state: PurchaseFormState) => DeepPartial<PurchaseFormState>)) => {
    const newValues = typeof newValuesOrFn === 'function' ? newValuesOrFn(state) : newValuesOrFn;
    setState(
      prevState => {
        const newState = _.merge({}, prevState, newValues);
        // @ts-ignore
        window.dataLayer = [...(window.dataLayer || []),
          {
            email: newState.formValues?.email,
            firstName: newState.formValues?.firstName,
            lastName: newState.formValues?.lastName,
            phoneNumber: newState.formValues?.phoneNumber,
            addressLine1: newState.formValues?.addressLine1,
            addressPostalCode: newState.formValues?.addressPostalCode,
            addressCity: newState.formValues?.addressCity,
          }
        ];
        return newState;
      }
    );
  };

  const handleNext = async (i?: number) => {
    if(i == null) {
      i = state.currentStep;
    }
    if(i < 9) {
      globalThis.history.pushState({ page: i+1 }, "", "");
      mergeState({ currentStep: i+1 });
    }
  }

  useEffect(() => {
    globalThis.history.replaceState({ page: state.currentStep }, "", "");
  }, []);

  window.onpopstate = (event) => {
    mergeState({ currentStep: event.state.page ?? state.currentStep-1 });
  };

  const syntheseProps = mapStateToSyntheseProps(state, mergeState, () => handleNext(7));

  return (
    <PurchaseFormView
      state={state}
      syntheseProps={syntheseProps}
      onVehiculeValuesChange={newValues => {
        mergeState({ formValues: { ...newValues } });
        if(Object.keys(newValues).includes('brand')) {
          mergeState({ formValues: { brandModel: newValues['brandModel'] ?? null } });
        }
      }}
      handleBorneKeyChange={borneKey => {
        mergeState({ formValues: {
          borne: borneKey,
          options: Object.fromEntries(productsData.optionsData.filter(optionData => optionData.default).map(optionData => [optionData.key, true])),
        } });
      }}
      handleCompteurKeyChange={currentKey => {
        mergeState({ formValues: {
          current: currentKey,
          borne: productsData.bornesData.find(borneData => borneData.availableForCurrents.includes(currentKey))?.key ?? state.formValues.borne,
          options: Object.fromEntries(productsData.optionsData.filter(optionData => optionData.default).map(optionData => [optionData.key, true])),
        } });
      }}
      handleDistanceInMetersChange={distanceInMeters => {
        mergeState({ formValues: {
          distanceInMeters: Math.max(productsData.distanceData.freeDistanceInMeters, Math.min(productsData.distanceData.maxDistanceInMeters, distanceInMeters)),
        } });
      }}
      handleOptionToggle={key => {
        mergeState({ formValues: { options: { [key]: !state.formValues.options[key] } } });
      }}
      handleDeadlineKeyChange={deadlineKey => {
        mergeState({ formValues: { deadline: deadlineKey } });
      }}
      onPersonalDetailsValuesChange={newValues => {
        mergeState({ formValues: { ...newValues } });
      }}
      onBillingDetailsValuesChage={newValues => {
        mergeState({ formValues: { ...newValues } });
      }}
      handleNext={handleNext}
    />
  );
}

export default PurchaseFormContainer;
