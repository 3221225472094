type ProductsData = {
    bornesData: ({
        image: string;
        ribbon?: string;
        key: string;
        name: string;
        borneType: string;
        description: string;
        properties: { key: string, label: string, description?: string, value?: string }[];
        current: string;
        availableForCurrents: string[];
        price: number;
        addons: {
            distance: {
                price: number;
            };
            moduleDelestage: {
                price: number;
            };
            moins72h: {
                isOffered: boolean;
                originalPrice: number;
                price: number;
            };
        };
    })[];
    currentsData: {
        monophase: {
            name: string;
            description: string;
            ribbon: string;
        };
        triphase: {
            name: string;
            description: string;
        };
    };
    distanceData: {
        freeDistanceInMeters: number;
        maxDistanceInMeters: number;
        freeDescription: string;
        excessDescription: string;
    };
    optionsData: ({
        key: string;
        name: string;
        description: string;
        default?: boolean;
    })[];
    deadlinesData: {
        key: string;
        name: string;
        icon: string;
    }[];
};

const productsData = (globalThis as any).productsData as ProductsData ?? {
  get borneData() { return (globalThis as any).productsData.borneData ?? [] },
  get habitationData() { return (globalThis as any).productsData.habitationData ?? [] },
  get compteurData() { return (globalThis as any).productsData.compteurData ?? [] },
  get distanceData() { return (globalThis as any).productsData.distanceData ?? {
    freeDistanceInMeters: 0,
    maxDistanceInMeters: 0,
    pricePerMeter: 0,
    freeDescription: '',
    excessDescription: '',
  } },
  get optionsData() { return (globalThis as any).productsData.optionsData ?? [] },
  get deadlineData() { return (globalThis as any).productsData.deadlineData ?? [] },
};

export default productsData;