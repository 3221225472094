import ListWithIcons from "./ListWithIcons";

const propertiesKeysIconsMap = {
  tempsDeRecharge: "/icon_tempsDeRecharge.svg",
  puissance: "/icon_puissance.svg",
  connectee: "/icon_connectee.svg",
  garantie: "/icon_garantie.svg",
  delestage: "/icon_delestage.svg",
  fiabilite: "/icon_fiabilite.svg",
  suiviIntelligent: "/icon_suiviIntelligent.svg",
  interrupteurEtCoffretElectrique: "/icon_interrupteurEtCoffretElectrique.svg",
  disjoncteurDifferentiel: "/icon_disjoncteurDifferentiel.svg",
  cheminementDeCableEtGaine: "/icon_cheminementDeCableEtGaine.svg",
  installationEtMiseEnService: "/icon_installationEtMiseEnService.svg",
  deuxBadgesRfidOffert: "/icon_deuxBadgesRfidOffert.svg",
  moins72h: "/icon_moins72h.svg",
  paiementPlusieursFois: "/icon_paiementPlusieursFois.svg",
};

const BornePropertiesList = ({ properties }: { properties: { key: string, label: string, description?: string, value?: string }[] }) => {
  return <ListWithIcons
    rows={properties.map(x => ({
      // @ts-ignore
      imagePath: propertiesKeysIconsMap[x.key],
      label: x.label,
      description: x.description,
      value: x.value,
    }))}
  />
}

export default BornePropertiesList;