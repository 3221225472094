import styled from "styled-components";
import Footer from "./components/Footer";
import React, { ReactNode } from "react";
import { PurchaseFormState } from "../PurchaseFormState";
import VehiculeStepView from "./steps/VehiculeStepView";
import PersonalDetailsStepView from "./steps/PersonalDetailsStepView";
import SyntheseStepView from "./steps/SyntheseStepView";
import BorneStepView from "./steps/BorneStepView";
import CurrentStepView from "./steps/CurrentStepView";
import DistanceStepView from "./steps/DistanceStepView";
import OptionsStepView from "./steps/OptionsStepView";
import DeadlineStepView from "./steps/DeadlineStepView";
import Header from "./components/Header";
import { SyntheseProps } from "../mapStateToProps";
import productsData from "../data/products";
import CommandeStepContainer from "./steps/CommandeStepContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  justify-content: start;
`;

const Content = styled.main`
  margin: 0 10px;
  max-width: 1000px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GlobalLayout = ({ currentStep, children }: {
  currentStep: number,
  children: ReactNode,
}) => {
  return (
    <Container>
      <Header numberOfSteps={10} currentStep={currentStep} />
      <Content>
        { children }
        <Footer/>
      </Content>
    </Container>
  );
};

export const PurchaseFormView = ({ state, syntheseProps, onVehiculeValuesChange, handleBorneKeyChange, handleCompteurKeyChange, handleDistanceInMetersChange, handleOptionToggle, onPersonalDetailsValuesChange, onBillingDetailsValuesChage, handleDeadlineKeyChange, handleNext, }: {
  state: PurchaseFormState,
  syntheseProps: SyntheseProps,
  onVehiculeValuesChange: (values: any) => void,
  handleBorneKeyChange: (value: string) => void,
  handleCompteurKeyChange: (value: string) => void,
  handleDistanceInMetersChange: (value: number) => void,
  handleOptionToggle: (option: string) => void,
  handleDeadlineKeyChange: (value: string) => void,
  onPersonalDetailsValuesChange: (values: any) => void,
  onBillingDetailsValuesChage: (values: any) => void,
  handleNext: (i: number) => void,
}) => {

  const selectedBorneData = productsData.bornesData.find(borneData => borneData.key === state.formValues.borne && borneData.availableForCurrents.includes(state.formValues.current));
  const availableOptionsData = productsData.optionsData.filter(optionData => !!selectedBorneData?.addons?.[optionData.key as keyof typeof selectedBorneData["addons"]]).map(optionData => ({ ...optionData, price: 0, ...selectedBorneData?.addons?.[optionData.key as keyof typeof selectedBorneData["addons"]] }));

  return (
    <GlobalLayout currentStep={state.currentStep}>
      { state.currentStep === 0 && <VehiculeStepView brand={state.formValues.brand} brandModel={state.formValues.brandModel} onValuesChange={onVehiculeValuesChange} handleNext={() => handleNext(0)} /> }
      { state.currentStep === 1 && <DistanceStepView distanceData={productsData.distanceData} distanceInMeters={state.formValues.distanceInMeters} handleDistanceInMetersChange={handleDistanceInMetersChange} handleNext={() => handleNext(1)} /> }
      { state.currentStep === 2 && <CurrentStepView currentsData={productsData.currentsData} currentKey={state.formValues.current} handleCurrentKeyChange={handleCompteurKeyChange} handleNext={() => handleNext(2)} /> }
      { state.currentStep === 3 && <DeadlineStepView deadlinesData={productsData.deadlinesData} deadlineKey={state.formValues.deadline} handleDeadlineKeyChange={handleDeadlineKeyChange} handleNext={() => handleNext(3)} /> }
      { state.currentStep === 4 && <BorneStepView availableBornesData={productsData.bornesData.filter(borneData => borneData.availableForCurrents.includes(state.formValues.current))} borneKey={state.formValues.borne} handleBorneKeyChange={handleBorneKeyChange} handleNext={() => handleNext(4)} /> }
      { state.currentStep === 5 && <OptionsStepView availableOptionsData={availableOptionsData} selectedOptions={state.formValues.options} handleOptionToggle={handleOptionToggle} handleNext={() => handleNext(5)} /> }
      { state.currentStep === 6 && <PersonalDetailsStepView formValues={state.formValues} onValuesChange={onPersonalDetailsValuesChange} handleNext={() => handleNext(6)} /> }
      { state.currentStep === 7 && <SyntheseStepView {...syntheseProps} /> }
      { state.currentStep === 8 && <CommandeStepContainer state={state} onValuesChange={onBillingDetailsValuesChage} /> }
    </GlobalLayout>
  );
};