import FancyButton from "../components/FancyButton";
import StickyFooter from "../components/StickyFooter";

const NextButtonFooter = (props: {
  hidden?: boolean,
  onClick?: () => void,
}) => {
  return <>{!props.hidden && (
    <StickyFooter>
      <FancyButton onClick={props.onClick}>Continuer</FancyButton>
    </StickyFooter>
  )}</>;
}

export default NextButtonFooter;