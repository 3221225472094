import { Button } from "antd";
import { formatAmount } from "../../utils/monetary";
import H2 from "../components/text/H2";
import Paragraphe from "../components/text/Paragraphe";
import CounterCard from "./cards/CounterCard";
import StyledCard from "./cards/StyledCard";
import { StyledCardsRow } from "./cards/StyledCardsRow";
import H1 from "../components/text/H1";
import productsData from "../../data/products";
import NextButtonFooter from "./NextButtonFooter";

const DistanceStepView = ({
  distanceData,
  distanceInMeters,
  handleDistanceInMetersChange,
  handleNext,
}: {
  distanceData: { freeDistanceInMeters: number, freeDescription: string, excessDescription: string },
  distanceInMeters: number,
  handleDistanceInMetersChange: (value: number) => void,
  handleNext: () => void,
}) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <H1>Distance entre votre tableau électrique et le lieu
        d’installation de la borne</H1>

      <StyledCardsRow>
        <CounterCard
          habitationType={`Moins de ${distanceData.freeDistanceInMeters} mètres`}
          description={distanceData.freeDescription}
          selected={distanceInMeters <= distanceData.freeDistanceInMeters}
          onClick={() => {
            handleDistanceInMetersChange(distanceData.freeDistanceInMeters);
            handleNext();
          }}
        />
        <StyledCard
          selected={distanceInMeters > distanceData.freeDistanceInMeters}
        >
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <div style={{ fontSize: "18px", fontWeight: "700" }}>{`Plus de ${distanceData.freeDistanceInMeters} mètres`}</div>
              <div style={{ fontSize: "18px", fontWeight: "500" }}></div>
            </div>
            <div style={{ paddingBottom: "18px", fontSize: "18px", fontWeight: "500" }}>
              {distanceData.excessDescription}
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              margin: "auto",
              justifyContent: "space-between",
              fontSize: "22px",
              border: "2px solid #293276",
              borderRadius: "25px",
              height: "45px",
              alignItems: "center",
            }}>
              <Button
                ghost
                onClick={() => {
                  handleDistanceInMetersChange(distanceInMeters - 1);
                }}
                style={{
                  border: "none",
                  borderRadius: "25px",
                  height: "unset",
                  color: "#293276",
                  fontWeight: "600",
                  fontSize: "22px",
                }}
              >-</Button>
              <div>{distanceInMeters}m</div>
              <Button
                ghost
                style={{
                  border: "none",
                  borderRadius: "25px",
                  height: "unset",
                  color: "#293276",
                  fontWeight: "600",
                  fontSize: "22px",
                }}
                onClick={() => {
                  handleDistanceInMetersChange(distanceInMeters + 1);
                }}
              >+</Button>
            </div>
          </div>
        </StyledCard>
      </StyledCardsRow>

      <NextButtonFooter onClick={handleNext} />
    </div>
  );
};

export default DistanceStepView;
