import { Input } from "antd";
import { styled } from "styled-components";

const StyledInput = styled(Input)`
  border: 2px solid #BEC0CB;
  border-radius: 25px;
  height: 45px;
  font-size: 18px;
  font-weight: 500;
  color: #293276;
  &:hover {
    border: 2px solid #293276;
  }
`;

export default StyledInput;