import StyledCard from "./cards/StyledCard";
import H1 from "../components/text/H1";

const DeadlineStepView = ({
  deadlinesData,
  deadlineKey,
  handleDeadlineKeyChange,
  handleNext,
}: {
  deadlinesData: { key: string, name: string, icon: string }[],
  deadlineKey: string,
  handleDeadlineKeyChange: (value: string) => void,
  handleNext: () => void,
}) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <H1>Votre projet est prévu pour ?</H1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '1.2em' }}>
        {deadlinesData.map((deadlineData) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: deadlineKey === deadlineData.key ? "2px solid #293276" : "2px solid #BEC0CB",
              borderRadius: "25px",
              color: "#293276",
              width: "100%",
              height: "100%",
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: "8px 16px",
              boxSizing: 'border-box',
              fontSize: '18px',
              fontWeight: '500',
            }}
            onClick={() => {
              handleDeadlineKeyChange(deadlineData.key);
              handleNext();
            }}
          >
            <div>{deadlineData.name}</div>
            <img src={deadlineData.icon} style={{ width: '32px', height: '32px', }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeadlineStepView;
