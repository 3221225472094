import type { Stripe } from "@stripe/stripe-js";

export type PurchaseFormState = {
  currentStep: number,
  stripePromise: Promise<Stripe | null> | Stripe | null,
  stripeClientSecret: string | null,
  stripeAmount: number | null,
  isLoadingToNextStep: boolean,
  payButtonState: "idle" | "loading",
  quoteButtonState: "idle" | "loading",
  totalAmount: number | null,
  depositPercentage: number | null,
  depositAmount: number | null,
  vatRatePercentage: number | null,
  formValues: {
    brand: string | null,
    brandModel: string | null,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    phoneNumber: string | null,
    addressLine1: string | null,
    addressPostalCode: string | null,
    addressCity: string | null,
    borne: string,
    habitationKey: number,
    current: string,
    deadline: string,
    distanceInMeters: number,
    options: Record<string, boolean>,
    customerType: 'individual' | 'company',
    companyName: string | null,
    billingAddressLine1: string | null,
    billingAddressPostalCode: string | null,
    billingAddressCity: string | null,
    billingEmail: string | null,
    billingPhoneNumber: string | null,
  },
};

export const defaultPurchaseFormState: PurchaseFormState = {
  currentStep: 0,
  stripePromise: null,
  stripeClientSecret: null,
  stripeAmount: null,
  isLoadingToNextStep: false,
  payButtonState: "idle",
  quoteButtonState: "idle",
  totalAmount: null,
  depositPercentage: null,
  depositAmount: null,
  vatRatePercentage: 20,
  formValues: {
    brand: null,
    brandModel: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    addressLine1: null,
    addressPostalCode: null,
    addressCity: null,
    borne: 'maxichargerPro',
    habitationKey: 1,
    current: 'monophase',
    deadline: 'asap',
    distanceInMeters: 10,
    options: {
    },
    customerType: 'individual',
    companyName: null,
    billingAddressLine1: null,
    billingAddressPostalCode: null,
    billingAddressCity: null,
    billingEmail: null,
    billingPhoneNumber: null,
  },
};