import BorneCard from "./cards/BorneCard";
import { formatAmount } from "../../utils/monetary";
import { StyledCardsRow } from "./cards/StyledCardsRow";
import H1 from "../components/text/H1";

import productsData from "../../data/products";
import NextButtonFooter from "./NextButtonFooter";

const BorneStepView = ({
  availableBornesData,
  borneKey,
  handleBorneKeyChange,
  handleNext,
}: {
  availableBornesData: { key: string, name: string, borneType: string, description: string, properties: { key: string, label: string, description?: string, value?: string }[], price?: number, ribbon?: string, image: string }[],
  borneKey: string,
  handleBorneKeyChange: (value: string) => void,
  handleNext: () => void,
}) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <H1>Formule tout compris<br/>Solution de charge + Installation</H1>
      <StyledCardsRow>
        {availableBornesData.map((borneData) => (
          <BorneCard
            image={borneData.image}
            borneType={borneData.borneType}
            name={borneData.name}
            description={borneData.description}
            properties={borneData.properties}
            price={borneData.price != null ? formatAmount(borneData.price) : undefined}
            ribbon={borneData.ribbon}
            selected={borneKey === borneData.key}
            onClick={() => {
              handleBorneKeyChange(borneData.key);
              handleNext();
            }}/>
        ))}
      </StyledCardsRow>

      {/*<NextButtonFooter onClick={handleNext} />*/}
    </div>
  );
};

export default BorneStepView;
