import styled from "styled-components";


const CardButton = styled.button<{ selected?: boolean; }>`
  width: 100%;
  border: 2px solid #293276;
  border-radius: 25px;
  background: transparent;
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
  color: #293276;
  ${props => {
    if (props.selected) {
      return `
      background: #293276;
      color: white;
      *:hover {
        color: white;
      }
        `;
    }
    return "";
  }};
`;

export default CardButton;