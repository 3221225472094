import { Radio } from "antd";
import { styled } from "styled-components";

const StyledRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #293276;
    background-color: #293276;
  }
  font-size: 18px;
  font-weight: 500;
  color: #293276;
`;

export default StyledRadio;